import React, { useContext, createContext, useEffect, useState } from 'react';
import useWindowWidth from '../hooks';
import { ContainerQuerySliceT } from '../types';

import AOS from 'aos';
import 'aos/dist/aos.css';

type ContextType = {
  queryState: ContainerQuerySliceT;
  setIsDark: React.Dispatch<React.SetStateAction<boolean>>;
  isDark: boolean;
};

type Props = {
  children: React.ReactNode;
};

const AppContext = createContext<ContextType | null>(null);
export const AppProvider: React.FC<Props> = ({ children }) => {
  const { queryState } = useWindowWidth();
  const [isDark, setIsDark] = useState(false);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      mirror: false,
    });
  }, []);

  return <AppContext.Provider value={{ queryState, setIsDark, isDark }}>{children}</AppContext.Provider>;
};

export const useAppContext = () => {
  const ctx = useContext(AppContext);
  if (ctx === null) {
    throw new Error('AuthContext missing.');
  }

  return ctx;
};
