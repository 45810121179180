import { useState, useEffect } from 'react';
export const CONTAINER_QUERIES = {
  isMobile: {
    maxWidth: 743,
  },
  isOnlyTablet: {
    minWidth: 744,
    maxWidth: 1179,
  },
  isDesktop: {
    minWidth: 1180,
  },
};

const useWindowWidth = () => {
  const [queryState, setQueryState] = useState({
    isMobile: false,
    isOnlyTablet: false,
    isDesktop: false,
  });

  useEffect(() => {
    const checkMediaQueries = () => {
      const isMobile = window.matchMedia(`(max-width: ${CONTAINER_QUERIES.isMobile.maxWidth}px)`).matches;
      const isOnlyTablet = window.matchMedia(
        `(min-width: ${CONTAINER_QUERIES.isOnlyTablet.minWidth}px) and (max-width: ${CONTAINER_QUERIES.isOnlyTablet.maxWidth}px)`,
      ).matches;
      const isDesktop = window.matchMedia(`(min-width: ${CONTAINER_QUERIES.isDesktop.minWidth}px)`).matches;

      setQueryState({ isMobile, isOnlyTablet, isDesktop });
    };

    checkMediaQueries();

    window.addEventListener('resize', checkMediaQueries);

    return () => {
      window.removeEventListener('resize', checkMediaQueries);
    };
  }, []);

  return { queryState };
};

export default useWindowWidth;
