export enum ROUTER_PATHS {
  home = '/',
  noMatch = '*',
  privacy = 'privacy',
  termOfUse = 'terms-and-conditions',
  features = '#Features',
  howItWorks = '#How it works',
  account = '#account',
}

export enum ButtonVariant {
  TEXT = 'TEXT',
  PRIMARY = 'PRIMARY',
}

export type ContainerQuerySliceT = {
  isMobile: boolean;
  isOnlyTablet: boolean;
  isDesktop: boolean;
};
