import React, { ButtonHTMLAttributes, FC, useState } from 'react';
import { ButtonVariant } from '../../app/types';

import styles from './index.module.scss';

interface MyButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: ButtonVariant;
  color?: string;
  hoverColor?: string;
  activeColor?: string;
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}
export const AppButton: FC<MyButtonProps> = ({
  variant = 'TEXT',
  color,
  className,
  children,
  hoverColor,
  activeColor,
  onClick,
  disabled,
  ...props
}) => {
  const [isClicked, setIsClicked] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setIsClicked(true);
    setTimeout(() => setIsClicked(false), 200);
    if (onClick) {
      onClick(event);
    }
  };

  const buttonClass = `${styles.appButton} ${styles[variant]} ${className ? className : ''} ${isClicked ? styles.clicked : ''}`;

  return (
    <button
      className={buttonClass}
      onClick={handleClick}
      style={
        {
          '--button-background': disabled ? '#cca516' : color || undefined,
          '--button-hover-background': hoverColor || undefined,
          '--button-active-background': activeColor || undefined,
          '--box-shadow-color': color
            ? `rgba(${parseInt(color.slice(1, 3), 16)}, ${parseInt(color.slice(3, 5), 16)}, ${parseInt(color.slice(5, 7), 16)}, 0.5)`
            : 'rgba(24, 144, 255, 0.8)',
          cursor: disabled ? 'not-allowed' : 'pointer',
          //eslint-disable-next-line
        } as any
      }
      {...props}
    >
      {children}
    </button>
  );
};
