import React, { FC } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { ROUTER_PATHS } from '../types';
import { Main } from '../../pages/Main';
import { Privacy } from '../../pages/Privacy';
import { TermPolicy } from '../../pages/TermPolicy';
import { MenuLayout } from '../../components/MenuLayout';

export const AppRouter: FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={ROUTER_PATHS.noMatch} element={<Navigate to={ROUTER_PATHS.home} />} />
        <Route element={<MenuLayout />}>
          <Route path={ROUTER_PATHS.home} element={<Main />} />
          <Route path={ROUTER_PATHS.privacy} element={<Privacy />} />
          <Route path={ROUTER_PATHS.termOfUse} element={<TermPolicy />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
