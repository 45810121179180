import { Navigation } from '../../modules/Navigation';

import { HeroSection } from '../../modules/HeroSection';
import { FeaturesSection } from '../../modules/FeaturesSection';
import { HowItWorksSection } from '../../modules/HowItWorksSection';
import { Footer } from '../../modules/Footer';
import { MainWrapper } from '../../components/MainWrapper';
import { META_DESCRIPTION, TITLES } from '../../lib/constants';
import { Helmet } from 'react-helmet';
import { useScrollToId } from '../../app/hooks/useScrollToId';
export const Main = () => {
  useScrollToId();

  return (
    <>
      <Helmet>
        <title>{TITLES.homepage}</title>
        <meta name="description" content={META_DESCRIPTION.homePage} />
      </Helmet>
      <MainWrapper>
        <Navigation />
        <HeroSection />
        <FeaturesSection />
        <HowItWorksSection />
        <Footer />
      </MainWrapper>
    </>
  );
};
