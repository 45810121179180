import React, { FC } from 'react';

import './styles/reset.css';
import './styles/global.scss';
import styles from './styles/index.module.scss';
import { AppRouter } from './routes/AppRouter';
import { AppProvider } from './providers/useProvider';
export const App: FC = () => {
  return (
    <AppProvider>
      <div className={styles.app}>
        <AppRouter />
      </div>
    </AppProvider>
  );
};
