import { ScanIcon, SettingsIcon, ChoiceIcon } from '../../assets/icons';
import {
  step1Light,
  step2Light,
  step3Light,
  step4Light,
  step6Light,
  step1Dark,
  step2Dark,
  step4Dark,
  step6Dark,
  step5Light,
  step5Dark,
} from './../../assets/images';
export const NAVIGATION_TITLES = ['Features', 'How it works'];

export const TITLES = {
  privacy: 'Privacy Policy',
  homepage: 'Your Supplements App',
  termsAndConditions: 'Terms & Conditions',
};

export const META_DESCRIPTION = {
  privacy: 'MyVitaAI’s Privacy Policy.',
  homePage: 'Empowering you to organically optimize your health.',
  termsAndConditions: 'MyVitaAI’s Terms & Conditions.',
};

export const FEATURE_LIST = [
  {
    icon: ScanIcon,
    title: 'Truly Personalized Nutrients Profile',
    description:
      'List of all your Micros & Macros with your ability to set your own Daily Goals and Upper Limits.',
  },
  {
    icon: SettingsIcon,
    title: 'Digital Inventory of Your Supplements',
    description: 'All Your Supplements in One Place with Live Information Personalized to You.',
  },
  {
    icon: ChoiceIcon,
    title: 'Supplements Information based on Ingredients',
    description: 'Ingredients Compatibility Check based on Your Unique Profile & Daily Goals.',
  },
];

export const HOW_IT_WORKS_LIST = [
  {
    id: 1,
    title: 'Create Your Account',
    description: 'Quickly set up your profile to personalize your experience.',
    imageLight: step1Light,
    imageDark: step1Dark,
  },
  {
    id: 2,
    title: 'Add Your Supplements',
    description: 'Easily add the supplements you’re currently taking or plan to start.',
    imageLight: step2Light,
    imageDark: step2Dark,
  },
  {
    id: 3,
    title: 'Track Your Intakes',
    description: 'Select the serving size of one or more supplements and swipe to log & track your intake.',
    imageLight: step3Light,
    imageDark: step5Dark,
  },
  {
    id: 4,
    title: 'Manage All Your Supplements',
    description: 'See all your supplements in one place, set your schedule & monitor your inventory.',
    imageLight: step4Light,
    imageDark: step4Dark,
  },
  {
    id: 5,
    title: 'View Trends of Nutrients Taken',
    description: 'Select a nutrient to see your intakes trends.',
    imageLight: step5Light,
    imageDark: step5Dark,
  },
  {
    id: 6,
    title: 'Check Supplement',
    description: 'Take a picture of a supplement to check its ingredients compatibility with your profile.',
    imageLight: step6Light,
    imageDark: step6Dark,
    beta: 'BETA',
  },
];

export const AppLink = 'https://apps.apple.com/us/app/myvitaai/id6695720082';
