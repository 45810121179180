import React, { FC } from 'react';
import styles from './index.module.scss';
import { Typography } from '../../../../components/Typography';
import { ComponentTypes } from '../../../../types';

type Feature = {
  icon: React.FunctionComponent;
  title: string;
  description: string;
};

type Props = {
  feature: Feature;
  index: number;
};

export const FeatureListItem: FC<Props> = ({ feature, index }) => {
  const IconComponent = feature.icon;

  return (
    <div
      data-aos="fade-right"
      data-aos-delay={index * 200}
      className={styles.featureItem}
      key={feature.title}
    >
      <div className={styles.featureIconWrapper}>
        <IconComponent />
      </div>

      <div className={styles.featureTextWrapper}>
        <Typography className={styles.featureTitle} componentType={ComponentTypes.Title} level="h3">
          {feature.title}
        </Typography>
        <Typography className={styles.featureDescription} componentType={ComponentTypes.Paragraph} level="h3">
          {feature.description}
        </Typography>
      </div>
    </div>
  );
};
