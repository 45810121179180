import React from 'react';
import { Typography } from '../../components/Typography';
import { ComponentTypes } from '../../types';
import { AppButton } from '../../components/AppButton';

import styles from './index.module.scss';

import layoutTopLeft from '../../assets/images/layout-top-left-min.png';
import layoutMidRight from '../../assets/images/layout-mid-right-min.png';

import cn from 'classnames';
import { ButtonVariant } from '../../app/types';
import { ProgressBar } from './components/ProgressBar';
import { useAppContext } from '../../app/providers/useProvider';
import { AnimatedLayout } from '../../components/AnimatedLayout';
import { HOW_IT_WORKS_LIST } from '../../lib/constants';
import { openAppLink } from '../../lib/functions';

export const HowItWorksSection = () => {
  const {
    queryState: { isMobile, isDesktop },
    isDark,
  } = useAppContext();

  return (
    <section className={styles.container} id="Howitworks">
      <Typography
        dataAos="fade-down"
        className={styles.title}
        componentType={ComponentTypes.Title}
        level="h2"
      >
        How it works
      </Typography>

      <Typography dataAos="fade-up" className={styles.subTitle} componentType={ComponentTypes.Paragraph}>
        The app is designed to personalize your experience with your own supplements.
      </Typography>

      {isMobile ? (
        <>
          <div className={styles.appDemonstrationContainerMobile}>
            <ProgressBar />

            {HOW_IT_WORKS_LIST.map((item) => {
              const image = isDark ? item.imageDark : item.imageLight;

              return (
                <div key={item.id} data-aos="fade-left" className={styles.appDemonstrationSectionMobile}>
                  <Typography
                    className={styles.appDemonstrationTitle}
                    componentType={ComponentTypes.Title}
                    level="h3"
                  >
                    {item.title}
                  </Typography>

                  {item.beta && <div className={styles.betaMobile}>{item.beta}</div>}

                  <Typography
                    className={cn(styles.appDemonstrationSubTitle, styles.appAppDemonstrationSubTitleMobile)}
                    componentType={ComponentTypes.Paragraph}
                  >
                    {item.description}
                  </Typography>

                  <img className={styles.image} src={image} alt="get-myvitaAi-app" />
                </div>
              );
            })}
          </div>
          <div data-aos="fade-right">
            <AppButton
              className={styles.startButton}
              hoverColor="#F7B300"
              activeColor="#F5A700"
              color="#FDCC1B"
              variant={ButtonVariant.PRIMARY}
              onClick={openAppLink}
            >
              <Typography className={styles.buttonText} componentType={ComponentTypes.Link}>
                Start now
              </Typography>
            </AppButton>
          </div>
        </>
      ) : (
        <div className={styles.appDemonstrationContainer}>
          {isDesktop && (
            <>
              <AnimatedLayout
                image={layoutTopLeft}
                style={{
                  left: -500,
                  height: 600,
                  top: -190,
                  transform: 'scale(1.3)',
                }}
              />

              <AnimatedLayout
                image={layoutMidRight}
                style={{
                  right: -440,
                  top: 800,
                  height: 800,
                }}
              />
            </>
          )}

          <ProgressBar />

          {HOW_IT_WORKS_LIST.map((item, index) => {
            const isEven = (index + 1) % 2 === 0;
            const image = isDark ? item.imageDark : item.imageLight;

            if (isEven) {
              return (
                <div key={item.id} className={styles.appDemonstrationSection}>
                  <div data-aos="fade-right" className={styles.innerLeftSection}>
                    <img className={styles.image} src={image} alt="create-account-myvitaAi-app" />
                  </div>

                  <div data-aos="fade-left" className={styles.innerRightSection}>
                    <div className={styles.textBlock}>
                      <div className={styles.titleWrapper}>
                        <Typography
                          className={cn(styles.appDemonstrationTitle)}
                          componentType={ComponentTypes.Title}
                          level="h3"
                        >
                          {item.title}
                        </Typography>
                        {item.beta && <div className={styles.beta}>{item.beta}</div>}
                      </div>

                      <Typography
                        className={cn(styles.appDemonstrationSubTitle, styles.subTitleLeft)}
                        componentType={ComponentTypes.Paragraph}
                      >
                        {item.description}
                      </Typography>
                    </div>
                  </div>
                </div>
              );
            }

            return (
              <div key={item.id} className={styles.appDemonstrationSection}>
                <div data-aos="fade-right" className={styles.innerLeftSection}>
                  <div className={styles.textBlock}>
                    <Typography
                      className={cn(styles.appDemonstrationTitle, styles.textRight)}
                      componentType={ComponentTypes.Title}
                      level="h3"
                    >
                      {item.title}
                    </Typography>
                    <Typography
                      className={cn(styles.appDemonstrationSubTitle, styles.subTitleRight)}
                      componentType={ComponentTypes.Paragraph}
                    >
                      {item.description}
                    </Typography>
                  </div>
                </div>

                <div data-aos="fade-left" className={styles.innerRightSection}>
                  <img className={styles.image} src={image} alt="get-myvitaAi-app" />
                </div>
              </div>
            );
          })}
        </div>
      )}

      {!isMobile && (
        <div data-aos="fade-right" className={styles.startButtonDesktopContainer}>
          <AppButton
            className={styles.startButtonDesktop}
            hoverColor="#F7B300"
            activeColor="#F5A700"
            color="#FDCC1B"
            variant={ButtonVariant.PRIMARY}
            onClick={openAppLink}
          >
            <Typography className={styles.buttonText} componentType={ComponentTypes.Link}>
              Start now
            </Typography>
          </AppButton>
        </div>
      )}
    </section>
  );
};
