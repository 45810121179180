import React, { FC } from 'react';

import styles from './index.module.scss';

type Props = {
  image: string;
  style: React.CSSProperties;
};

export const AnimatedLayout: FC<Props> = ({ image, style }) => {
  return <div className={styles.container} style={{ backgroundImage: `url(${image})`, ...style }} />;
};
