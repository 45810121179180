import React, { useState } from 'react';
import { Typography } from '../../components/Typography';
import { ComponentTypes } from '../../types';

import styles from './index.module.scss';
import { AppStoreIcon } from '../../assets/icons';
import { AppButton } from '../../components/AppButton';
import { ButtonVariant, ROUTER_PATHS } from '../../app/types';
import Modal from 'react-modal';
import { useAppContext } from '../../app/providers/useProvider';
import cn from 'classnames';
import { openAppLink } from '../../lib/functions';

Modal.setAppElement('#root');
export const Footer = () => {
  const [formState, setFormState] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const {
    isDark,
    queryState: { isMobile },
  } = useAppContext();
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    setFormState(value);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (formState) {
      fetch('https://formsubmit.co/ajax/info@myvita.ai', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify({
          email: formState,
        }),
      })
        .then((response) => {
          return response.json();
        })
        .then()
        .catch((error) => console.error('Error:', { error }));

      setTimeout(() => {
        setFormState('');
        setModalIsOpen(true);
      }, 1000);
    }
  };

  return (
    <footer className={styles.footerWrapper}>
      <div className={styles.top}>
        <div>
          <Typography className={styles.title} componentType={ComponentTypes.Title}>
            Stay up to date
          </Typography>
          <div className={styles.inputWrapper}>
            <form onSubmit={handleSubmit}>
              <AppButton
                disabled={!formState}
                className={styles.button}
                hoverColor="#F7B300"
                activeColor="#F5A700"
                color="#FDCC1B"
                variant={ButtonVariant.PRIMARY}
                type="submit"
              >
                Submit
              </AppButton>
              <input type="hidden" name="_captcha" value="false" />
              <input
                onChange={handleChange}
                value={formState}
                className={styles.input}
                placeholder="Your email"
                type="email"
                name="email"
              />
            </form>
          </div>
        </div>

        <AppButton onClick={openAppLink}>
          <AppStoreIcon />
        </AppButton>
      </div>

      <div className={styles.bottom}>
        <Typography className={cn(styles.text, styles.unscrapableText)} componentType={ComponentTypes.Title}>
          info@myvita.ai
        </Typography>

        <div className={styles.row}>
          <Typography className={styles.text} componentType={ComponentTypes.Title}>
            MyVitaAI © 2024
          </Typography>
          <div className={styles.round} />
          <Typography href={ROUTER_PATHS.privacy} className={styles.text} componentType={ComponentTypes.Link}>
            Privacy Policy
          </Typography>
          <div className={styles.round} />
          <Typography
            href={ROUTER_PATHS.termOfUse}
            className={styles.text}
            componentType={ComponentTypes.Link}
          >
            Terms & Conditions
          </Typography>
        </div>

        <Typography className={styles.text} componentType={ComponentTypes.Paragraph}>
          Protected by a U.S utility patent & other patent pending applications.
        </Typography>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        style={{
          content: {
            width: isMobile ? 'calc(100% - 32px)' : 400,
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            border: 'none',
            padding: '24px 16px',
            borderRadius: '10px',
            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)',
            backgroundColor: isDark ? '#3A3D43' : '#F9FBFC',
          },
          overlay: {
            backgroundColor: 'transparent',
          },
        }}
      >
        <div className={styles.modalContent}>
          <Typography className={styles.title} componentType={ComponentTypes.Title}>
            Thank you!
          </Typography>
          <Typography className={styles.subTitle} componentType={ComponentTypes.Paragraph}>
            You have been added to our mailing list
          </Typography>

          <AppButton
            className={styles.modalButton}
            hoverColor="#FDCC1B"
            activeColor="#F5A700"
            color="#FDCC1B"
            variant={ButtonVariant.PRIMARY}
            onClick={() => setModalIsOpen(false)}
          >
            <Typography className={styles.buttonText} componentType={ComponentTypes.Link}>
              Close
            </Typography>
          </AppButton>
        </div>
      </Modal>
    </footer>
  );
};
