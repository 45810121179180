import { MainWrapper } from '../../components/MainWrapper';
import { Navigation } from '../../modules/Navigation';
import { TermOfUse } from '../../modules/TermOfUse';
import { Helmet } from 'react-helmet';
import { META_DESCRIPTION, TITLES } from '../../lib/constants';

export const TermPolicy = () => {
  return (
    <>
      <Helmet>
        <title>{TITLES.termsAndConditions}</title>
        <meta name="description" content={META_DESCRIPTION.termsAndConditions} />
      </Helmet>

      <MainWrapper>
        <Navigation />
        <TermOfUse />
      </MainWrapper>
    </>
  );
};
