import { FC } from 'react';
import { Typography } from '../../components/Typography';
import { ComponentTypes } from '../../types';
import { AppLink, NAVIGATION_TITLES } from '../../lib/constants';
import { AppButton } from '../../components/AppButton';

import {
  BurgerMenuIcon,
  // InstLightIcon,
  // InstDarkIcon,
  // XDarkIcon,
  // XLightIcon,
  LogoLightIcon,
  LogoDarkIcon,
} from '../../assets/icons';

import styles from './index.module.scss';
import { ButtonVariant, ROUTER_PATHS } from '../../app/types';
import { useAppContext } from '../../app/providers/useProvider';
import { DarkModeToggle } from '../../components/DarkModeToggle';
import { useNavigate } from 'react-router-dom';
import { useMenuLayoutContext } from '../../components/MenuLayout';
import { openAppLink } from '../../lib/functions';

export const Navigation: FC = () => {
  const {
    queryState: { isOnlyTablet, isMobile, isDesktop },
    isDark,
  } = useAppContext();

  const { handleMenuToggle } = useMenuLayoutContext();

  const navigate = useNavigate();

  const onClickLogo = () => {
    navigate(ROUTER_PATHS.home);
  };

  const handleRedirectAndScroll = (id: string) => {
    const preparedId = id.split(' ').join('');

    navigate(`/#${preparedId}`);
  };

  return (
    <header className={styles.container} id="Home">
      <div className={styles.header}>
        <div className={styles.headerLeft}>
          {(isMobile || isOnlyTablet) && (
            <AppButton className={styles.burgerButton} onClick={handleMenuToggle}>
              <BurgerMenuIcon fill={isDark ? '#ffffff' : '#111827'} />
            </AppButton>
          )}

          <AppButton onClick={onClickLogo}>{isDark ? <LogoLightIcon /> : <LogoDarkIcon />}</AppButton>
        </div>

        {isDesktop && (
          <nav className={styles.nav}>
            <ul className={styles.navList}>
              {NAVIGATION_TITLES.map((navTitle) => (
                <Typography
                  key={navTitle}
                  className={styles.navListItemName}
                  componentType={ComponentTypes.Title}
                  onClick={() => handleRedirectAndScroll(navTitle)}
                >
                  {navTitle}
                </Typography>
              ))}
            </ul>
          </nav>
        )}

        <div className={styles.buttonsContainer}>
          <div className={styles.iconsContainer}>
            {/*<DarkModeToggle />*/}

            {/*<AppButton className={styles.iconButton}>*/}
            {/*  {isDark ? <InstDarkIcon /> : <InstLightIcon />}*/}
            {/*</AppButton>*/}

            {/*<AppButton className={styles.iconButton}>{isDark ? <XDarkIcon /> : <XLightIcon />}</AppButton>*/}
          </div>

          {(isDesktop || isOnlyTablet) && (
            <AppButton
              className={styles.downloadButton}
              hoverColor="#F7B300"
              activeColor="#F5A700"
              color="#FDCC1B"
              variant={ButtonVariant.PRIMARY}
              onClick={openAppLink}
            >
              <Typography className={styles.downloadButtonText} componentType={ComponentTypes.Link}>
                Download
              </Typography>
            </AppButton>
          )}
        </div>
      </div>
    </header>
  );
};
