import { useEffect } from 'react';
import styles from './index.module.scss';

export const TermOfUse = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://app.termly.io/embed-policy.min.js';
    script.async = true;

    document.body.appendChild(script);
  }, []);

  return (
    <div
      //eslint-disable-next-line
      // @ts-ignore
      name="termly-embed"
      data-id="f0e75478-ca7e-4880-bada-2e90544bf883"
      data-type="iframe"
      className={styles.container}
    ></div>
  );
};
