import React, { FC, ReactNode, useMemo } from 'react';
import { ComponentTypes, TypographyLevels } from '../../types';

type Props = {
  children: ReactNode;
  componentType: ComponentTypes;
  className?: string;
  level?: TypographyLevels;
  href?: string;
  dataAos?: string;
  onClick?: () => void;
};

export const Typography: FC<Props> = ({
  children,
  componentType,
  onClick,
  className,
  level,
  href,
  dataAos,
}) => {
  const Component = useMemo(() => {
    if (componentType === ComponentTypes.Link) {
      return 'a';
    } else if (componentType === ComponentTypes.Paragraph) {
      return 'p';
    } else if (componentType === ComponentTypes.Title) {
      return level ? level : 'h1';
    } else {
      return 'span';
    }
  }, [componentType, level]);

  return (
    <Component
      style={{
        cursor: componentType === ComponentTypes.Link ? 'pointer' : 'auto',
      }}
      data-aos={dataAos}
      className={className}
      href={href}
      onClick={onClick}
    >
      {children}
    </Component>
  );
};
