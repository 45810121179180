import { useEffect } from 'react';

import styles from './index.module.scss';

export const PrivacyPolicy = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://app.termly.io/embed-policy.min.js';
    script.async = true;

    document.body.appendChild(script);
  }, []);

  return (
    <div
      //eslint-disable-next-line
      // @ts-ignore
      name="termly-embed"
      data-id="e5abeab0-0a01-4e0b-be25-cb58050c53c6"
      data-type="iframe"
      className={styles.container}
    ></div>
  );
};
